import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A short symposium was held in the Department of Earth Sciences in Cambridge on
Friday February  9 this year. The theme was to honour the memory of Alan Smith
and some of the many ways he  contributed to teaching, research and scientific
progress over his career. The event was held the  day before his memorial
service in St John's College Chapel and was followed by a meal attended  by
Alan's daughter, her family and some of Alan's many friends in a Cambridge
restaurant that  evening. `}</p>
    <p>{`Speakers at the mini-symposium included Nigel Woodcock, Euan Nisbet, James
Jackson, Andy  Gale, Robert Hall, Colin Reeves and Roy Livermore. Peter Friend
took the chair. Each speaker was  allowed 20 minutes and more than 50 people
filled the lecture theatre used for the event. An  informal reception was held
in the Sedgwick Museum immediately afterwards. Even people who  knew Alan well
were surprised by the breadth of the work Alan had contributed to through his
enthusiasm and perceptively critical - but always friendly - outlook. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      